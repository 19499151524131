import { useEffect, useRef, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import YouTube, { YouTubeEvent } from 'react-youtube';
import { ResizeObserver as PolyfillResizeObserver } from '@juggle/resize-observer';

// utils
import { youtubeSettingsSelector } from 'utils/selectors/globalsSelectors';
import { useUCConsent, VideoConsent } from 'features/cookie-consent';
import { useTracking } from 'utils/tracking/track';

interface ConfigurableYoutubeProps {
  videoId: string;
  playerControl: boolean;
  language: string;
  showLink: boolean;
}

function ConfigurableYoutube({
  language,
  videoId = '',
  playerControl = true,
  showLink = true,
}: Readonly<ConfigurableYoutubeProps>) {
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<ResizeObserver | undefined>(undefined);
  const [videoWidth, setVideoWidth] = useState<number>(0);
  const [videoHeight, setVideoHeight] = useState<number>(0);
  const globalYoutubeData = useSelector(youtubeSettingsSelector);
  const ucConsent = useUCConsent();
  const { autoplay, playInline, removeRelated, showAnnotations, showFullscreen, videoResolution } =
    globalYoutubeData ?? {};

  // tracking
  const progressRef = useRef<number>(0);
  const videoStartedRef = useRef<boolean>(false);
  const { trackYoutubeVideo } = useTracking();
  let intervalId: NodeJS.Timeout;
  const checkProgress = async (event: YouTubeEvent) => {
    const currentTime = await event.target.getCurrentTime();
    const totalDuration = await event.target.getDuration();
    const percent = Math.floor((currentTime * 100) / totalDuration);
    if (progressRef.current < 25 && percent >= 25) {
      progressRef.current = 25;
      trackYoutubeVideo(event, videoId, 'geberit video', videoStartedRef.current, 25);
    } else if (progressRef.current < 50 && percent >= 50) {
      progressRef.current = 50;
      trackYoutubeVideo(event, videoId, 'geberit video', videoStartedRef.current, 50);
    } else if (progressRef.current < 75 && percent >= 75) {
      progressRef.current = 75;
      trackYoutubeVideo(event, videoId, 'geberit video', videoStartedRef.current, 75);
    }
  };

  const resizeAspectRatio = useCallback(() => {
    if (videoContainerRef.current) {
      const divWidth = videoContainerRef.current.getBoundingClientRect().width;
      const factor = videoResolution === '16:9' ? 9 / 16 : 1;
      setVideoWidth(divWidth);
      setVideoHeight(divWidth * factor);
    }
  }, [videoResolution]);

  useEffect(() => {
    if (!globalYoutubeData) {
      return () => {};
    }

    const ResizeObserver = window.ResizeObserver || PolyfillResizeObserver;
    observerRef.current = new ResizeObserver(resizeAspectRatio);
    observerRef.current.observe(document.body);

    return () => {
      observerRef.current?.disconnect();
    };
  }, [globalYoutubeData, resizeAspectRatio]);

  if (!globalYoutubeData) {
    return null;
  }

  return (
    <div className="c-text-video__video" ref={videoContainerRef}>
      {ucConsent.Youtube === true && videoWidth > 0 && videoHeight > 0 && (
        <YouTube
          videoId={videoId}
          opts={{
            height: videoHeight,
            width: videoWidth,
            playerVars: {
              autoplay: autoplay ? 1 : 0,
              controls: playerControl ? 1 : 0,
              modestbranding: showLink ? undefined : 1,
              hl: language,
              cc_lang_pref: language,
              rel: removeRelated ? 0 : 1,
              fs: showFullscreen ? 1 : 0,
              iv_load_policy: showAnnotations ? 1 : 3,
              playsinline: playInline ? 1 : 0,
            },
          }}
          onPlay={(event) => {
            trackYoutubeVideo(event, videoId, 'geberit video', videoStartedRef.current);
            intervalId = setInterval(() => checkProgress(event), 1000); // Store the interval ID
            videoStartedRef.current = true;
          }}
          onEnd={(event) => {
            clearInterval(intervalId);
            trackYoutubeVideo(event, videoId, 'geberit video', progressRef.current);
          }}
          onPause={() => {
            clearInterval(intervalId); // Clear the interval using the stored ID
          }}
        />
      )}
      {ucConsent.Youtube === false && <VideoConsent type="youtube" />}
    </div>
  );
}

export default ConfigurableYoutube;
